import * as React from "react";
import {Link} from "gatsby";
import Nav from "./nav/nav";

import * as styles from "./header.module.scss";
import {StaticImage} from "gatsby-plugin-image";

const Header = () => {

  // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
  if (typeof window !== 'undefined') {
    let prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      const maxScroll = document.body.clientHeight - window.innerHeight;
      let currentScrollPos = window.pageYOffset;
      if (
        (maxScroll > 0 && prevScrollPos > currentScrollPos && prevScrollPos <= maxScroll)
        || (maxScroll <= 0 && prevScrollPos > currentScrollPos)
        || (prevScrollPos <= 0 && currentScrollPos <= 0)
      ) {
        document.getElementById("navbar").style.top = "0";
      } else {
        document.getElementById("navbar").style.top = "-8rem"; // adjustable based your need
      }
      prevScrollPos = currentScrollPos;
    }
  }

  return (
    <header className={styles.header} id="navbar">

      <div className={styles.flexContainer}>
        <Link to="/">
          <StaticImage src="../../images/logo.png"
                       alt="Logo"
                       className={styles.logo}
                       backgroundColor={"white"}
                       objectFit={"contain"}
                       loading={"eager"}
          />
        </Link>

        <Nav />
      </div>
    </header>
  );
};

export default Header;
