export const navItems = [
  {
    title: "Domů",
    url: "/#domu",
  },
  {
    title: "O Projektu",
    url: "/#o-projektu",
  },
  {
    title: "Nabídka bytů",
    url: "/#byty",
  },
  {
    title: "Další projekty",
    url: "/#projekty",
  },
  {
    title: "Kontakt",
    url: "/#kontakt",
  },
]
