import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {Link} from "gatsby";
import {navItems} from "../../../config";

import * as styles from './nav.module.scss';

const Nav = ({light}) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const onLinkClick = () => {
    setMenuOpened(false);
  };

  useEffect(() => {
    if (menuOpened) {
      document?.body?.classList?.add('no-scroll');
    } else {
      document?.body?.classList?.remove('no-scroll');
    }
  }, [menuOpened]);

  return (
    <nav
      className={classNames({
        [styles.nav]: true,
        [styles.menuOpened]: menuOpened,
      })}
    >
      <button
        aria-label="Menu"
        aria-controls="menu-list"
        className={classNames({
          hamburger: true,
          'hamburger--spin': true,
          'is-active': menuOpened,
          [styles.menuToggle]: true,
        })}
        onClick={() => setMenuOpened(!menuOpened)}
      >
				<span className="hamburger-box">
					<span className={`${styles.hamburgerInner} hamburger-inner`} />
				</span>
      </button>

      <div className={styles.menuList} id="menu-list">
        <ul className={styles.flexContainer}>
          {navItems.map(item => (
            <li key={item.title}
                className={styles.menuItem}
            >
              <Link to={item?.url} onClick={() => onLinkClick()}>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
